<template>
	<div class="max-w-full">
		<slot name="top">
			<nk-container variant="wide" class="rfs-mb-7">
				<div v-if="modulHero">
					<div class="relative">
						<nk-carousel variant="hero-portal" class="global-hero">
							<template
								v-for="(bildupload, bilduploadIndex) in [
									{
										bild: modulHero.content.bildupload1.bild || null,
										bildtext: modulHero.content.bildupload1.bildtext,
										copyright: modulHero.content.bildupload1.bildcopyright,
									},
									{
										bild: modulHero.content.bildupload2.bild || null,
										bildtext: modulHero.content.bildupload2.bildtext,
										copyright: modulHero.content.bildupload2.bildcopyright,
									},
									{
										bild: modulHero.content.bildupload3.bild || null,
										bildtext: modulHero.content.bildupload3.bildtext,
										copyright: modulHero.content.bildupload3.bildcopyright,
									},
									{
										bild: modulHero.content.bildupload4.bild || null,
										bildtext: modulHero.content.bildupload4.bildtext,
										copyright: modulHero.content.bildupload4.bildcopyright,
									},
									{
										bild: modulHero.content.bildupload5.bild || null,
										bildtext: modulHero.content.bildupload5.bildtext,
										copyright: modulHero.content.bildupload5.bildcopyright,
									},
								]"
								:key="bilduploadIndex"
							>
								<nk-carousel-slide
									v-if="bildupload.bild"
									:bg-image-copyright="bildupload.copyright || null"
									bg-image-copyright-position="right top"
									:has-bg-parallax="modulHero.content.parallax === 'on'"
									:bg-image="bildupload.bild"
									:bg-alt="bildupload.bildtext"
								/>
							</template>
						</nk-carousel>
						<div
							v-if="modulHero.content.titel || modulHero.content.untertitel"
							class="container px-4 mx-auto absolute bottom-0 right-0 md:right-40 z-10 pointer-events-none flex sm:justify-end"
						>
							<div
								class="relative bg-primary-600 rounded-t-custom bg-opacity-95 space-y-3 text-white inline-block p-8 md:max-w-xl lg:w-7/12 xl:max-w-2xl pointer-events-auto"
							>
								<div v-if="modulHero.content.untertitel" class="mb-3">
									<nk-meta color="white" size="sm">
										{{ modulHero.content.untertitel }}
									</nk-meta>
								</div>
								<nk-title
									v-if="modulHero.content.titel"
									size="4xl"
									color="white"
									tag="h1"
									no-margin
								>
									{{ modulHero.content.titel }}
								</nk-title>
							</div>
						</div>
					</div>
				</div>
				<nk-carousel v-if="modulHeroSlider" variant="hero" class="global-hero">
					<template
						v-for="(heroSlide, heroSlideIndex) in [
							{
								titel: modulHeroSlider.content.titel1,
								untertitel: modulHeroSlider.content.untertitel1,
								link: modulHeroSlider.content.link1,
								linktext: modulHeroSlider.content.linktext1,
								bildupload: modulHeroSlider.content.bildupload1,
							},
							{
								titel: modulHeroSlider.content.titel2,
								untertitel: modulHeroSlider.content.untertitel2,
								link: modulHeroSlider.content.link2,
								linktext: modulHeroSlider.content.linktext2,
								bildupload: modulHeroSlider.content.bildupload2,
							},
							{
								titel: modulHeroSlider.content.titel3,
								untertitel: modulHeroSlider.content.untertitel3,
								link: modulHeroSlider.content.link3,
								linktext: modulHeroSlider.content.linktext3,
								bildupload: modulHeroSlider.content.bildupload3,
							},
							{
								titel: modulHeroSlider.content.titel4,
								untertitel: modulHeroSlider.content.untertitel4,
								link: modulHeroSlider.content.link4,
								linktext: modulHeroSlider.content.linktext4,
								bildupload: modulHeroSlider.content.bildupload4,
							},
							{
								titel: modulHeroSlider.content.titel5,
								untertitel: modulHeroSlider.content.untertitel5,
								link: modulHeroSlider.content.link5,
								linktext: modulHeroSlider.content.linktext5,
								bildupload: modulHeroSlider.content.bildupload5,
							},
						]"
						:key="`heroSlide-${heroSlideIndex}`"
					>
						<nk-carousel-slide
							v-if="
								heroSlide.titel || heroSlide.bildupload.bild || heroSlide.link
							"
							:class="modulHeroSlider.content.parallax ? 'parallax' : null"
							:bg-image="
								(heroSlide.bildupload && heroSlide.bildupload.bild) || null
							"
							:bg-image-copyright="
								(heroSlide.bildupload && heroSlide.bildupload.bildcopyright) ||
								null
							"
							bg-image-copyright-position="right top"
							:has-bg-parallax="modulHeroSlider.content.parallax === 'on'"
						>
							<div v-if="heroSlide.titel" class="container mx-auto">
								<div class="max-w-xl p-4 text-shadow-sm">
									<nk-title size="5xl" color="white" no-margin>
										{{ heroSlide.titel }}
									</nk-title>
									<nk-text
										v-if="heroSlide.untertitel"
										color="white"
										size="lg"
										class="font-display font-normal"
									>
										{{ heroSlide.untertitel }}
									</nk-text>
									<nk-button v-if="heroSlide.link" :link="heroSlide.link">
										{{ heroSlide.linktext || 'Mehr erfahren' }}
									</nk-button>
								</div>
							</div>
						</nk-carousel-slide>
					</template>
				</nk-carousel>
				<nk-breadcrumb
					home-title="Startseite"
					:breadcrumbs="page.breadcrumbs"
					:home-path="homePath"
				/>
			</nk-container>
		</slot>
		<main ref="mainArea">
			<slot name="content">
				<template
					v-for="(module, indexModuleContent) in page.context.filter(
						module =>
							module.slotName === 'content' ||
							typeof module.slotName === 'undefined',
					)"
					:key="indexModuleContent"
				>
					<component
						v-bind="{
							context: module.context,
							options: module.options,
						}"
						:is="componentName(module.template)"
					/>
				</template>
			</slot>
		</main>
		<slot name="bottom" />
	</div>
</template>

<script>
import PageLayout from '#nk/mixins/page-layout';
import {useCMS} from '#nk/composables/cms';

export default {
	name: 'NkPagePortal',

	mixins: [PageLayout],

	props: {
		homePath: {
			type: String,
			required: false,
			default: '/',
		},
	},

	setup() {
		const page = useCMS();

		return {
			page,
		};
	},

	computed: {
		modulHero() {
			return (
				this.page.context[0].context.find(item => item.modul.name === 'hero') ||
				null
			);
		},
		modulHeroSlider() {
			return (
				this.page.context[0].context.find(
					item => item.modul.name === 'hero-slider',
				) || null
			);
		},
	},
};
</script>
